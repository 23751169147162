<script lang="ts" setup>
import { useQuasar } from 'quasar';

const quasar = useQuasar();
quasar.iconMapFn = (iconName: string) => {
  if (iconName === 'vista') {
    return {
      icon: 'img:/icons/vista.icon.svg'
    };
  }
};
</script>
<template>
  <router-view />
</template>
